import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import ArtistsGrid from "../components/artists-grid"

const ArtistsPage = () => {
  return (
    <Layout>
      <SEO title="Artists" />
      <div className="max-w-5xl mx-auto w-full">
        <div className="my-8 text-center">
          <h1 className="my-8">Artists</h1>
        </div>
      </div>
      <ArtistsGrid />
    </Layout>
  )
}

export default ArtistsPage
